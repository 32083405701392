import React from "react"

function UnderConstructionPage() {
    return (
        <h1 style={{
            textAlign:"center", 
            paddingTop:250, 
            paddingBottom:250}}>
                Whoops! Current page is under construction!
        </h1>
    );
}

export default UnderConstructionPage;